import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import ResumesList from '../components/resumes-list'

import SEO from '../components/seo'

export default ({ data, pageContext }) => {
    const { locations, designations } = pageContext
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            noSecondaryHeader
            marketTicker
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    link: '/jobs',
                    title: 'Real Estate Careers'
                },
                { title: 'Real Estate CVs' }
            ]}
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <ResumesList
                data={data}
                locations={locations}
                designations={designations}
            />
        </Layout>
    )
}

export const query = graphql`
    query {
        allWordpressWpCv {
            nodes {
                title
                content
                acf {
                    designation
                    email
                    country
                    phone
                    expected_salary
                    experience
                    address
                    cv {
                        link
                    }
                }
            }
        }
        allWordpressPage(filter: { slug: { eq: "resumes" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
