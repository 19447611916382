import React, { useState } from 'react'

import styles from './style.module.scss'

import Select from 'react-select'

import * as primaryPalette from '../../styles/primary-pallete'

import Pagination, { paginate } from '../pagination'

import { FaDownload } from 'react-icons/fa'

import { formatter } from '../format'

import isEmpty from 'lodash.isempty'

import cn from 'classnames'

function removeDuplicates(options) {
    return options.reduce((unique, item) => {
        const found = !!unique.find(uItem => uItem.value === item.value)

        return found ? unique : [...unique, item]
    }, [])
}

var _locationOptions = null
const locationOptions = data => {
    if (_locationOptions) return _locationOptions

    _locationOptions = data.allWordpressWpCv.nodes.map(
        resume =>
            !isEmpty(resume.acf.address) && {
                value: resume.acf.address.toLowerCase().trim(),
                label: formatter.titleCase(resume.acf.address)
            }
    )
    // filter empty and duplicates
    _locationOptions = _locationOptions.filter(c => c)

    _locationOptions = removeDuplicates(_locationOptions)

    _locationOptions = [
        {
            value: null,
            label: 'All locaitons'
        },
        ..._locationOptions
    ]

    return _locationOptions
}

var _careerOptions = null
const careerOptions = data => {
    if (_careerOptions) return _careerOptions

    _careerOptions = data.allWordpressWpCv.nodes.map(
        resume =>
            !isEmpty(resume.acf.designation) && {
                value: resume.acf.designation.toLowerCase().trim(),
                label: formatter.titleCase(resume.acf.designation)
            }
    )
    // filter empty and duplicates
    _careerOptions = _careerOptions.filter(c => c)

    _careerOptions = removeDuplicates(_careerOptions)

    _careerOptions = [
        {
            value: null,
            label: 'All designations'
        },
        ..._careerOptions
    ]
    return _careerOptions
}

function filterResumes(resumes, filter) {
    return resumes.filter(resume => {
        let matched = true
        if (!isEmpty(filter.address) && !isEmpty(filter.address.value)) {
            matched =
                matched &&
                resume.acf.address.toLowerCase().trim() === filter.address.value
        }

        if (
            !isEmpty(filter.designation) &&
            !isEmpty(filter.designation.value)
        ) {
            matched =
                matched &&
                resume.acf.designation.toLowerCase().trim() ===
                    filter.designation.value
        }

        return matched
    })
}

const selectTheme = theme => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        // primary25: secondaryPalette.base,
        primary: primaryPalette.base
    }
})

function transformFieldName(field) {
    const map = {
        title: 'name',
        content: null,
        link: null
    }

    return typeof map[field] !== 'undefined' ? map[field] : field
}

function renderResumeFields(resume) {
    if (!resume) return []
    return Object.keys(resume).map(field => {
        if (typeof resume[field] === 'object')
            return renderResumeFields(resume[field])
        let fieldName = transformFieldName(field)
        return (
            fieldName && (
                <tr>
                    <td className={styles.fieldKey}>
                        {formatter.snakeToTitleCase(fieldName)}
                    </td>
                    <td
                        className={styles.fieldValue}
                        dangerouslySetInnerHTML={{
                            __html: formatter.emptyValue(resume[field])
                        }}
                    />
                </tr>
            )
        )
    })
}

export default ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1)

    const [filter, setFilter] = useState({})

    const updateFilter = data => setFilter({ ...filter, ...data })

    const pageSize = 10

    const filtered = filterResumes(data.allWordpressWpCv.nodes, filter)

    const { array: resumes, numPages } = paginate({
        array: filtered,
        pageSize,
        currentPage
    })

    return (
        <div className="wrapper">
            <div className="row">
                <div className="col-xs-12">
                    <h1 className={styles.pageTitle}>Real Estate CVs</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6">
                    <div>What</div>
                    <Select
                        options={careerOptions(data)}
                        theme={selectTheme}
                        placeholder="Position"
                        onChange={designation => updateFilter({ designation })}
                    />
                </div>
                <div className="col-xs-6">
                    <div>Where</div>
                    <Select
                        options={locationOptions(data)}
                        theme={selectTheme}
                        placeholder="Dubai"
                        onChange={address => updateFilter({ address })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    {resumes.map((resume, i) => (
                        <div key={i} className={styles.job}>
                            <div className="row middle-xs">
                                <div className="col-xs-12 col-sm-6">
                                    <h3 className={styles.name}>
                                        {resume.title}
                                    </h3>
                                    <table className={styles.resumeFieldsTable}>
                                        {renderResumeFields(resume)}
                                    </table>
                                </div>
                                <div
                                    className={cn(
                                        'col-xs-12 col-sm-6',
                                        styles.downloadFileContainer
                                    )}
                                >
                                    <h3 className={styles.downloadCVTitle}>
                                        Download CV Now
                                    </h3>
                                    <div className={styles.downloadNowWrap}>
                                        <a
                                            href={resume.acf.cv.link}
                                            className={styles.downloadLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaDownload
                                                className={styles.downloadFile}
                                            />
                                        </a>
                                    </div>
                                    <p className={styles.posted}>
                                        Posted on Property ePortal
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {!numPages && (
                <div className="row">
                    <div className="col-xs-12">
                        <p>No CVs found! please refine your search criteria</p>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-xs-12">
                    <Pagination
                        numPages={numPages}
                        currentPage={currentPage}
                        onChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    )
}
